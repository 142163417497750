import iconsClassName from 'src/styles/fonts/waysbooks-icons/styles.css'
import { icons, iconsClassName as iconClass } from './iconsList'

export type IconType = keyof typeof icons;

export { icons }

export const getClassName = (icon: IconType) => {
  const iconKey = `wb-icon-${iconClass[icon]}` as keyof typeof iconsClassName

  return `wb-icon- ${iconsClassName[iconKey]} wb-icon wb-icon-${iconClass[icon]}`
}
