import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect, NavLink } from 'react-router-dom'
// import NotFound from 'src/pages/NotFound'
import { Dropdown, DropdownItem } from 'src/components/Dropdown'
import { Loader } from 'src/components/Loader/Loader'
import { authUserActions, selectUser } from 'src/coreLogic/useCases/authUser'
import { RequestIndicatorProvider } from 'src/hooks'
import { Account } from 'src/pages/Account'
import { Book } from 'src/pages/Book'
import { Collections } from 'src/pages/Collections'
import classes from './Authenticated.module.css'
import { SavingState } from './SavingState'

interface AppProps {
  username: string;
}

function App(props: AppProps) {
  return (
    <RequestIndicatorProvider>
      <div className={classes.container}>
        <header className={classes.header}>
          <div className={classes.headerLeft}>
            <NavLink className={classes.logo} to="/">
              <div className={classes.waysbooks} />
              <div className={classes.maker}>Maker</div>
            </NavLink>
            <nav className={classes.nav}>
              <NavLink
                activeClassName={classes.active}
                className={classes.navItem}
                to="/library"
              >
                Ma bibliothèque
              </NavLink>
            </nav>
          </div>
          <div className={classes.profile}>
            <SavingState />
            <Dropdown label={props.username}>
              <DropdownItem onClick={() => null} to="/account">
                Mes infos
              </DropdownItem>
              <DropdownItem onClick={() => null} to="/auth/logout">
                Deconnexion
              </DropdownItem>
            </Dropdown>
          </div>
        </header>
        <div className={classes.content}>
          <Switch>
            <Route component={Collections} exact path="/library" />
            <Route component={Book} path="/books/:bookId" />
            <Route component={Account} path="/account" />
            <Redirect exact from="/" to="/library" />
            {/* <Route path="*" component={NotFound} /> */}
          </Switch>
        </div>
      </div>
    </RequestIndicatorProvider>
  )
}

export function Authenticated() {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authUserActions.loginFromToken())
  }, [dispatch])

  if (!user) return <Loader overlay={true} />

  return (
    <Switch>
      <Route path="*" render={() => <App username={user.username} />} />
    </Switch>
  )
}
