import React from 'react'
import { ModalConfirm } from './ModalConfirm'

export const ModalFormContext = React.createContext(null)

type Props = ModalConfirm['props']

export class ModalConfirmForm extends React.PureComponent<Props> {
  static defaultProps = ModalConfirm.defaultProps

  // @ts-expect-error
  formApi: { submitForm: Function; }

  // @ts-expect-error
  resolve: Function

  // @ts-expect-error
  reject: Function

  onValidate = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
      this.formApi.submitForm()
    })
  }

  onSubmited = (res: any) => {
    this.resolve(res)
  }

  onSubmitFailure = (errors: any) => {
    if (this.reject) {
      this.reject(errors)
    }
  }

  render() {
    return (
      <ModalFormContext.Provider
        // @ts-expect-error
        value={{
          // @ts-expect-error
          getApi: (formApi) => {
            this.formApi = formApi
          },
          onSubmited: this.onSubmited,
          onSubmitFailure: this.onSubmitFailure,
        }}
      >
        <ModalConfirm {...this.props} onValidate={this.onValidate} />
      </ModalFormContext.Provider>
    )
  }
}
