import React from 'react'
import { Button } from 'src/components/Buttons'
import { Select } from 'src/components/Form/Fields/Select'
import { Icon } from 'src/components/Icon'
import { Colors } from 'src/styles/colors'
import * as S from './Condition.styles'
import { ConditionLink } from './ConditionLink'

type OptionItem = {
  label: string;
  value: string;
  values: Array<{
    label: string;
    value: string;
  }>;
}

type OptionGroup = {
  children: Array<OptionItem>;
  label: string;
}

type Option = OptionGroup | OptionItem

type Props = {
  enableEdit?: boolean;
  onChange: Function;
  options: Array<Option>;
  value: {
    type: string;
    /**
     * if value composed condition, so it's an array of condition
     */
    value: object[] | string;
  };
}

// @ts-expect-error
const findCurrentType = (options, { type }) => {
  if (!type) {
    return null
  }

  // eslint-disable-next-line
  for (const option of options) {
    if (option.children) {
      // @ts-expect-error
      const child = option.children.find((child) => child.value === type)
      if (child) return child
    } else if (option.value === type) {
      return option
    }
  }

  return null
}

// @ts-expect-error
const isComposedCondition = (type) => ['condition:and', 'condition:or'].includes(type)

export class Condition extends React.PureComponent<Props> {
  static defaultProps = {
    enableEdit: false,
    value: {},
  }

  onChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, onChange } = this.props
    onChange({
      ...value,
      value: event.target.value,
    })
  }

  onChangeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, onChange } = this.props
    const type = event.target.value

    const nextValue = {
      ...value,
      type,
    }

    // if it's a composed condition and the previous condition wasn't a composed condition
    if (isComposedCondition(type) && !isComposedCondition(value.type)) {
      nextValue.value = [
        {
          type: '',
          value: '',
        },
        {
          type: '',
          value: '',
        },
      ]
    } else {
      nextValue.value = ''
    }

    onChange(nextValue)
  }

  onChangeChildCondition = (index: number, newValue: Array<Object>) => {
    const { value, onChange } = this.props
    const { value: subConditions } = value

    if (Array.isArray(subConditions)) {
      subConditions[index] = newValue
    }

    onChange({ ...value, value: subConditions })
  }

  onAddCondition = () => {
    const { value, onChange } = this.props
    const { value: subConditions } = value

    if (Array.isArray(subConditions)) {
      subConditions.push({ type: '', value: '' })
    }

    onChange({ ...value, value: subConditions })
  }

  onDeleteSubCondition = (index: number) => {
    const { value, onChange } = this.props
    const { value: subConditions } = value

    if (Array.isArray(subConditions)) {
      subConditions.splice(index, 1)
    }

    onChange({ ...value, value: subConditions })
  }

  render() {
    const { value, options, enableEdit } = this.props

    const currentType = findCurrentType(options, value)

    return (
      <S.Container>
        <div className="field">
          <Select
            disabled={!enableEdit}
            onChange={this.onChangeType}
            options={[
              { label: 'Choisissez un type', value: '' },
              ...options,
              {
                label: 'Condition composée',
                children: [
                  {
                    label: 'Condition ET',
                    value: 'condition:and',
                  },
                  {
                    label: 'Condition OU',
                    value: 'condition:or',
                  },
                ],
              },
            ]}
            value={value.type}
          />
          {currentType && typeof value.value === 'string' && (
            <Select
              disabled={!enableEdit}
              onChange={this.onChangeValue}
              options={currentType.values}
              value={value.value}
            />
          )}
        </div>
        {isComposedCondition(value.type) && (
          <div className="subConditionsList">
            {/*
            // @ts-ignore */}
            {value.value.map((condition, index) => (
              // eslint-disable-next-line
              <React.Fragment key={index}>
                <div className="subCondition">
                  <Condition
                    enableEdit={enableEdit}
                    // @ts-expect-error
                    onChange={(value) => this.onChangeChildCondition(index, value)}
                    options={options}
                    value={condition}
                  />
                  {/* show trash only if there is more than 1 condition */}
                  {enableEdit && value.value.length > 1 && (
                    <Button
                      className="deleteBtn"
                      color={Colors.red}
                      icon="trash"
                      onClick={() => this.onDeleteSubCondition(index)}
                    />
                  )}
                </div>
                {/* Show AND or OR */}
                {index !== value.value.length - 1
                  ? (
                    <ConditionLink>
                      {value.type === 'condition:and' ? 'ET' : 'OR'}
                    </ConditionLink>
                  ) : (enableEdit && (
                    <ConditionLink
                      isAddAction={true}
                      onClick={this.onAddCondition}
                    >
                      <Icon icon="add" style={{ fontSize: 25 }} />
                    </ConditionLink>
                  )
                  )}
              </React.Fragment>
            ))}
          </div>
        )}
      </S.Container>
    )
  }
}
