import { Colors } from 'src/styles/colors'
import { confirm } from './confirm'

export const confirmDelete = (data?: any) => {
  return confirm({
    title: 'Suppression',
    titleColor: Colors.red,
    validateLabelColor: Colors.red,
    validateLabel: 'Supprimer',
    showCloseBtn: false,
    ...data,
  })
}
