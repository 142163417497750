import { StateFromReducersMapObject } from 'redux'
import { authUserReducer } from 'src/coreLogic/useCases/authUser'
import { reducerResources } from 'src/store/legacyResources/resourcesConfig'

const reducersFromResources = {
  ...reducerResources,
}

const typedReducers = {
  authUser: authUserReducer,
}

export const reducers = {
  ...reducersFromResources,
  ...typedReducers,
} as typeof typedReducers

export type AppState = StateFromReducersMapObject<typeof reducers>
export type PartialAppState = Partial<AppState>
