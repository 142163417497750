import { AuthUser } from '../../models/AuthUser.model'
import { AuthUserAction, AuthUserActionType } from './authUser.actions'
import { AuthUserErrors } from './authUser.errors'

export interface AuthUserState {
  user: AuthUser | null;
  errors: AuthUserErrors;
}

const defaultAuthUserState: AuthUserState = {
  user: null,
  errors: {},
}

export function authUserReducer(state: AuthUserState = defaultAuthUserState, action: AuthUserAction): AuthUserState {
  switch (action.type) {
    case AuthUserActionType.SET_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }

    case AuthUserActionType.SET_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      }
    }

    default:
      return state
  }
}
