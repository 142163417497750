import { css } from 'emotion'
import React from 'react'

type Props = {
  children: any;
}

const styles = css`
  padding: 10px 0;
  color: var(--color-red);
`

export class Message extends React.PureComponent<Props> {
  render() {
    return <div className={styles}>{this.props.children}</div>
  }
}
