import cx from 'classnames'
import React from 'react'
import { Icon } from 'src/components/Icon'
import { Loader } from 'src/components/Loader/Loader'
import { Colors } from 'src/styles/colors'
import { IconType } from 'src/styles/icons'
import { ButtonAsButton, ButtonAsLink, LoaderContainer } from './Button.styles'

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  color: Colors;
  disabled: boolean;
  icon?: IconType;
  isLoading: boolean;
  onClick?: Function;
  style?: React.CSSProperties;

  /**
   * generate a link <a href="" />, like <Link to="" />
   */
  to?: string;
  type: 'button' | 'submit';
}

export class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    type: 'button',
    isLoading: false,
    color: Colors.blueLight,
    disabled: false,
  }

  onClick = (event: React.MouseEvent) => {
    const { onClick, isLoading, disabled } = this.props
    if (!isLoading && onClick && !disabled) {
      onClick(event)
    }
  }

  render() {
    const {
      isLoading,
      type,
      disabled,
      to,
      className,
      children,
      icon,
      color,
      onClick,
      ...props
    } = this.props

    const finalColor = disabled ? Colors.grey : color

    const iconEl = icon && (
      <Icon
        icon={icon}
        style={{ fontSize: 18, paddingRight: children && 10 }}
      />
    )

    if (to) {
      return (
        // @ts-ignore
        <ButtonAsLink
          {...props}
          className={className}
          color={finalColor}
          to={to}
        >
          <div>
            {iconEl}
            {children}
          </div>
        </ButtonAsLink>
      )
    }
    return (
      <ButtonAsButton
        {...props}
        className={cx(
          iconEl && 'isIcon',
          className,
        )}
        color={finalColor}
        onClick={this.onClick}
        style={{
          ...props.style,
          paddingRight: isLoading ? 0 : undefined,
        }}
        type={type}
      >
        <div>
          {iconEl}
          {children}
          {isLoading && (
            <LoaderContainer>
              <Loader size="small" />
            </LoaderContainer>
          )}
        </div>
      </ButtonAsButton>
    )
  }
}
