import React from 'react'
import { Button } from 'src/components/Buttons'
import { Colors } from 'src/styles/colors'
import { Modal, defaultProps as defaultPropsModal } from './Modal'
import classes from './ModalConfirm.module.css'

type Props = {
  cancel?: boolean;
  cancelLabel?: string;
  cancelLabelColor?: Colors;
  onCancel?: Function;
  onRequestClose?: Function;
  /**
   * @return {bool|Promise}
   */
  onValidate?: Function;

  validate?: boolean;
  validateLabel?: string;
  validateLabelColor?: Colors;
} & Modal['props']

type State = {
  isLoading: boolean;
}

export const defaultProps = {
  validate: true,
  cancel: true,
  validateLabel: 'Valider',
  cancelLabel: 'Annuler',
  validateLabelColor: Colors.green,
  cancelLabelColor: Colors.grey,
  // @ts-expect-error
  titleColor: Colors.blackNew,
  ...defaultPropsModal,
}

export class ModalConfirm extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  childrenRef: React.ReactNode = null

  customRefs: Object = {}

  constructor(props: Props) {
    super(props)

    this.state = {
      isLoading: false,
    }
  }

  onValidate = () => {
    const { onValidate } = this.props

    if (!onValidate) return

    this.setState({ isLoading: true })

    const validate = onValidate(this.customRefs)

    if (validate === true) {
      this.onRequestClose()
    } else if (validate && validate.then) {
      validate
        // @ts-expect-error
        .then((res) => {
          if (typeof res === 'string') {
            this.onError(res)
            this.setState({ isLoading: false })
          } else if (res === false) {
            this.setState({ isLoading: false })
          } else {
            this.onRequestClose()
          }
        })
        // @ts-expect-error
        .catch((error) => {
          this.setState({ isLoading: false })
          throw error
        })
    } else if (typeof validate === 'string') {
      this.onError(validate)
    } else {
      console.warn(
        'ModalConfirm onValidate() must return a boolean or a Promise',
      )
    }
  }

  onCancel = () => {
    const { onCancel } = this.props
    if (onCancel) onCancel()
    this.onRequestClose()
  }

  // eslint-disable-next-line
  onError(error: string) {
    // notif({ error });
    this.setState({ isLoading: false })
  }

  onRequestClose() {
    const { onRequestClose } = this.props
    if (onRequestClose) {
      onRequestClose()
    }
  }

  getRefs = (refKey: string) => {
    return (c: React.ReactNode) => {
      // @ts-expect-error
      this.customRefs[refKey] = c
    }
  }

  render() {
    const {
      // modal props
      title,
      draggable,
      onRequestClose,

      // modal confirm props
      children,
      validate,
      validateLabel,
      validateLabelColor,
      cancel,
      cancelLabel,
      cancelLabelColor,
      ...props
    } = this.props
    return (
      <Modal
        draggable={draggable}
        onRequestClose={onRequestClose}
        showCloseBtn={false}
        title={title}
        {...props}
      >
        {children}
        <div className={classes.btns}>
          {cancel && (
            <Button
              color={cancelLabelColor}
              onClick={this.onCancel}
              // @ts-expect-error
              style={{ flex: 1, marginRight: validate && '20px' }}
            >
              {cancelLabel}
            </Button>
          )}
          {validate && (
            <Button
              color={validateLabelColor}
              isLoading={this.state.isLoading}
              onClick={this.onValidate}
              style={{ flex: 1 }}
            >
              {validateLabel}
            </Button>
          )}
        </div>
      </Modal>
    )
  }
}
