import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAuthUserError,
  selectUser,
} from 'src/coreLogic/useCases/authUser'

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
type Last<T extends any[]> = T extends [infer I, ...infer L] ? L : never;
/* eslint-enable @typescript-eslint/no-unused-vars */
/* eslint-enable no-unused-vars */
type LastsParameters<T extends (...args: any[]) => any> = Last<Parameters<T>>

function createHookSelector<T extends(...args: any[]) => any>(selector: T): (...params: LastsParameters<T>) => ReturnType<T> {
  return (...params) => useSelector((state: any) => selector(state, ...params))
}

const selectors = {
  authUser: {
    useSelectAuthUserError: createHookSelector(selectAuthUserError),
    useSelectUser: createHookSelector(selectUser),
  },
}

type SelectorsContextValue = typeof selectors

export type PartialSelectorsProviderValue = Partial<{
  [Key in keyof SelectorsContextValue]: Partial<SelectorsContextValue[Key]>
}>

const SelectorsContext = createContext<SelectorsContextValue>({} as SelectorsContextValue)

export function SelectorsProvider(props: { children: React.ReactNode; selectors: PartialSelectorsProviderValue; }) {
  const { children, selectors } = props

  return (
    <SelectorsContext.Provider value={selectors as SelectorsContextValue}>
      {children}
    </SelectorsContext.Provider>
  )
}

export function useSelectors() {
  const context = useContext(SelectorsContext)

  if (!context) {
    throw new Error('context Selectors can\'t be used outside context ActionsProviders')
  }

  return context
}

export function AppSelectorsProvider(props: { children: React.ReactNode; }) {
  const { children } = props

  return (
    <SelectorsProvider selectors={selectors}>
      {children}
    </SelectorsProvider>
  )
}
