// @ts-expect-error
import PubSub from 'pubsub-js'
import React from 'react'
import uniqid from 'uniqid'

export const openModalEventName = uniqid()

export const openModal = (modal: React.ReactElement<any>) => {
  PubSub.publish(openModalEventName, modal)
}
