import styled, { css } from 'styled-components'
import { Colors } from 'src/styles/colors'

const CSSInputTextarea = css`
  appearance: none;
  background-color: #fff;
  border: solid 1px ${Colors.blueLight};
  border-radius: 0px;
  box-sizing: border-box;
  color: ${Colors.greyDark};
  display: block;
  font-size: 17px;
  /* outline: none; */
  outline-color: ${Colors.yellowBorder};
  outline-width: 4px;
  padding: 10px 8px;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;

  &::placeholder {
    color: ${Colors.greyLight};
    font-family: 'ralewaylight_italic';
  }
`

export const Input = styled.input`
  ${CSSInputTextarea}
`

export const Textarea = styled.textarea`
  ${CSSInputTextarea}
`

export const RemainningCharacters = styled.div`
  text-align: right;
  color: #888;
`
