import { createBrowserHistory } from 'history'
import { IRouter } from 'src/coreLogic/dependencies/IRouter'

export class ReactRouter implements IRouter {
  static history = createBrowserHistory()

  redirectToDashboard() {
    this.push('/')
  }

  redirectToLogin() {
    const { pathname, search } = window.location
    let query = ''
    if (!pathname.startsWith('/auth/')) {
      const redirectTo = `${pathname}${search}`
      query = redirectTo ? `?redirect=${redirectTo}` : ''
    }

    this.push(`/auth/login${query}`)
  }

  isLoginPage() {
    return window.location.pathname === '/auth/login'
  }

  push(...params: Parameters<typeof ReactRouter.history.push>) {
    ReactRouter.history.push(...params)
  }
}

export const router = new ReactRouter()
