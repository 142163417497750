import validator from 'validator'
import { EmailErrors } from './authUser.errors'

export function validateEmail(email: string) {
  if (!email) {
    return EmailErrors.REQUIRED
  }

  if (!validator.isEmail(email)) {
    return EmailErrors.INVALID_EMAIL
  }

  return undefined
}

export function validatePassword(password: string) {
  if (!password) {
    return EmailErrors.REQUIRED
  }

  return undefined
}
