import React from 'react'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import { Field } from 'react-form'
import { Condition as ConditionField } from 'src/components/Form/Fields/Condition'
import { Field as FieldForm } from 'src/components/Form/Fields/Field'

type Props = Object & {
  enableEdit?: boolean;
  field: string;
  fieldClassName?: string;
  fieldStyle?: Object;
  label?: string;
  options: ConditionField['props']['options'];
}

export class Condition extends React.PureComponent<Props> {
  render() {
    const { field, fieldStyle, fieldClassName, label, ...props } = this.props
    return (
      <Field field={field}>
        {/* @ts-expect-error */}
        {(fieldApi) => {
          const { value, error, setValue } = fieldApi
          return (
            <FieldForm
              label={label}
              style={{ paddingBottom: error && 0, ...fieldStyle }}
            >
              <ConditionField
                {...props}
                // @ts-expect-error
                onChange={(value) => {
                  return setValue(value)
                }}
                value={value}
              />
            </FieldForm>
          )
        }}
      </Field>
    )
  }
}
