import cx from 'classnames'
import React from 'react'
import * as S from './ConditionLink.styles'

type Props = {
  children: any;
  isAddAction?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

export const ConditionLink = ({ children, isAddAction, ...props }: Props) => (
  <S.Container
    className={cx('container', { link: isAddAction })}
    {...props}
  >
    <div className="border" />
    <div className="content">{children}</div>
    {!isAddAction && <div className="border" />}
  </S.Container>
)
