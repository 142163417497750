import React, { useEffect } from 'react'
import useForm from 'react-hook-form'
import { Input } from 'src/components/Form/Fields'
import { Field } from 'src/components/Form/Fields/Field'
import { Form } from 'src/components/Form/ReactForm'
import { useActions } from 'src/main/ActionsProviders'
import { useSelectors } from 'src/main/SelectorsProvider'
import classes from './Style.module.css'

interface FormField {
  email: string;
  password: string;
}

export function Login() {
  const { authUser } = useActions()
  const { register, getValues } = useForm<FormField>()
  const errors = useSelectors().authUser.useSelectAuthUserError()

  const onSubmit = async () => {
    const { email, password } = getValues()

    authUser.login({ email, password })

    return true
  }

  useEffect(() => {
    authUser.loginFromToken()
  }, [authUser])

  return (
    <div className={classes.loginFormContainer}>
      <Form
        onSubmit={onSubmit}
        render={() => (
          <div>
            <Field error={errors?.email}>
              <Input
                data-testid="email"
                innerRef={register}
                name="email"
                placeholder="Email"
              />
            </Field>
            <Field error={errors?.password}>
              <Input
                data-testid="password"
                innerRef={register}
                name="password"
                placeholder="Password"
                type="password"
              />
            </Field>
          </div>
        )}
        submitButtonLabel="Connexion"
        validateOnSubmit={true}
      />
    </div>
  )
}
