import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'
import { IconType, getClassName } from 'src/styles/icons'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export interface IconProps {
  className?: string;
  icon: IconType;
  style?: object;
}

export function Icon(props: IconProps) {
  const { icon, className, ...restProps } = props

  return (
    <Container className={cx(className, getClassName(icon))} {...restProps} />
  )
}
