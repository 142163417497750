import styled from 'styled-components'
import selectArrow from 'src/img/icons/select-arrow.png'
import { Colors } from 'src/styles/colors'

export const Container = styled.div`
  position: relative;
  width: 100%;
`

// export const fieldError = styled.div`
//   position: absolute;
//   right: 0px;
// `

export const Select = styled.select`
  outline-color: ${Colors.blueLight};
  border: solid 1px ${Colors.blueLight};
  font-size: 17px;
  width: 100%;
  padding: 3px 8px;
  color: ${Colors.greyDark};
  border-radius: 0px;
  appearance: none;
  background-color: #fff;

  padding-right: 30px;
  background-image: url(${selectArrow});
  background-repeat: no-repeat;
  background-position: right 10px center;
  font-family: 'ralewaylight', inherit;

  option[disabled] {
    color: #f00;
  }

  &.error {
    border-color: ${Colors.red};
    box-shadow: 0 0 3px ${Colors.red};
  }
`
