import { API } from 'src/adapters/gateways/API'
import { IAuthUserGateway } from 'src/coreLogic/dependencies/IAuthUserGateway'
import { AuthUserErrorBadCredentials } from 'src/coreLogic/useCases/authUser/authUser.exceptions'

export class HTTPAuthUserGateway implements IAuthUserGateway {
  constructor(
    private api: API,
  ) { }

  authenticateUserFromToken(token: string) {
    return this.api
      .request<{ userId?: string; }>({
        url: `/accessTokens/${token}`,
      })
      .then((res) => {
        if (!res?.data?.userId) {
          return null
        }

        return this.api
          .request<{ id: string; username: string; }>({
            url: `/wbusers/${res.data.userId}/getWithAuthorizations`,
          })
          .then((res) => {
            return {
              id: res.data.id,
              username: res.data.username,
            }
          })
      })
      .catch(() => {
        return null
      })
  }

  logoutUser() {
    return this.api
      .request({
        method: 'POST',
        url: 'wbusers/logout',
      })
      .then(() => {
        return null
      })
  }

  loginUser(data: { email: string; password: string; }) {
    return this.api
      .request<{ id: string; }>({
        method: 'POST',
        url: 'wbusers/login',
        data,
      })
      .then((res) => {
        return res.data?.id || null
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          throw new AuthUserErrorBadCredentials()
        }

        throw error
      })
  }
}
