import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Layout, Login, Logout /* Register */ } from 'src/pages/Authentication'

export const Authentication = () => (
  <Layout>
    <Switch>
      {/* <Route path="/auth/register" component={Register} /> */}
      <Route component={Login} path="/auth/login" />
      <Route component={Logout} path="/auth/logout" />
    </Switch>
  </Layout>
)
