import React from 'react'

type Props = {
  children: React.ReactElement<any>;
  modal: React.ReactElement<any>;
}

type State = {
  modalIsOpen: boolean;
}

export class ModalTrigger extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)

    this.state = {
      modalIsOpen: false,
    }
  }

  render() {
    const { children, modal } = this.props
    const { modalIsOpen } = this.state

    return (
      <>
        {React.cloneElement(children, {
          onClick: () => {
            this.setState({ modalIsOpen: true })
          },
        })}
        {modalIsOpen
          && React.cloneElement(modal, {
            onRequestClose: () => {
              this.setState({ modalIsOpen: false })
            },
          })}
      </>
    )
  }
}
