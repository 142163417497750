import React from 'react'
import * as S from './Input.styles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  className?: string;
  innerRef?: any;
  maxLength?: number;
  multilines?: boolean;
  onBlur?: (event: React.ChangeEvent) => void;
  placeholder?: string;
  shiftStep?: number;
  style?: React.CSSProperties;
  type?: string;
  value?: string;
}

const UP_KEYCODE = 38
const DOWN_KEYCODE = 40

export class Input extends React.PureComponent<Props> {
  // @ts-expect-error
  nextValue?: number = null

  onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { onChange, type } = this.props
    const { target } = event
    if (onChange) {
      if (typeof this.nextValue === 'number') {
        onChange({
          ...event.nativeEvent,
          // @ts-ignore
          target: { ...target, value: this.nextValue },
        })
      } else if (type === 'number') {
        // cast to number
        onChange({
          ...event.nativeEvent,
          // @ts-ignore
          target: { ...target, value: Number(event.target.value) },
        })
      } else {
        onChange(event)
      }
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { type, shiftStep } = this.props
    const { keyCode, shiftKey, target } = event
    // @ts-expect-error
    this.nextValue = null

    if (shiftStep && type && type === 'number' && shiftKey) {
      const numberValue = Number((target as HTMLInputElement).value)
      if (keyCode === UP_KEYCODE) {
        this.nextValue = Math.round((numberValue + shiftStep) * 100) / 100
      } else if (keyCode === DOWN_KEYCODE) {
        this.nextValue = Math.round((numberValue - shiftStep) * 100) / 100
      }
    }
  }

  render() {
    const { shiftStep, innerRef, multilines, ...props } = this.props
    const { maxLength, value } = props
    const notEmptyValue = value === null ? '' : value
    const textareaRows = String(notEmptyValue).split('\n').length

    return (
      <div style={{ width: '100%' }}>
        {multilines
          ? (
            <S.Textarea
              {...props}
              ref={innerRef}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              rows={textareaRows}
              value={notEmptyValue}
            />
          ) : (
            <S.Input
              {...props}
              ref={innerRef}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              value={notEmptyValue}
            />
          )}
        {maxLength && (
          <S.RemainningCharacters>
            {maxLength - (notEmptyValue || '').length}
            {' '}
            caractères restants
          </S.RemainningCharacters>
        )}
      </div>
    )
  }
}
