import React from 'react'
import { NavLink } from 'react-router-dom'
import { DropdownItemContainer } from './Dropdown.styles'

interface DropdownItemProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  to?: string;
}

export function DropdownItem(props: DropdownItemProps) {
  return props.to
    // @ts-ignore
    ? <DropdownItemContainer as={NavLink} {...props} />
    : <DropdownItemContainer {...props} />
}
