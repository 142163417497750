import cogoToast from 'cogo-toast'
import { Subject } from 'rxjs'
import { INotification } from 'src/coreLogic/dependencies/INotification'

export class Notification implements INotification {
  static listenUncaughtError() {
    const notification = new Notification()

    window.onerror = (msg) => {
      notification.error$.next(msg.toString())
    }
  }

  message$ = new Subject<string>()

  error$ = new Subject<string>()

  constructor() {
    this.error$.subscribe((error) => {
      cogoToast.error(error, { hideAfter: 5 })
    })
  }
}

Notification.listenUncaughtError()
