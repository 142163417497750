import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import darken from 'src/styles/darken'

function getButtonCSS(color: string) {
  return css`
    color: #fff;
    border: solid 1px transparent;
    border-radius: 4px;
    height: 28px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0 10px;
    line-height: 20px;
    font-size: 14px;
    outline: 0 !important;
    outline-style: none;
    appearance: none;
    transition: background-color 0.2s;
    background-color: ${color};

    &:hover {
      background-color: ${darken(color)};
    }

    &:not(.isIcon) {
      font-family: 'avenir-next-bold', Helvetica;
      padding: 0 30px;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `
}

interface ButtonProps {
  color: string;
}

export const ButtonAsButton = styled.button<ButtonProps>`
  ${(props) => getButtonCSS(props.color)}
`

export const ButtonAsLink = styled(Link) <ButtonProps>`
  ${(props) => getButtonCSS(props.color)}
`

export const LoaderContainer = styled.div`
  width: 25px;
  margin-left: 5px;
`
