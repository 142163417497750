import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-left: -60px; */

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    text-align: center;
    background-color: var(--color-grey);
    color: #fff;
    font-weight: 700;
  }

  .border {
    height: 10px;
    border-left: 2px solid var(--color-grey);
  }

  .link .content:hover {
    cursor: pointer;
    background-color: #ccc;
  }

`
