import styled from 'styled-components'
import { Colors } from 'src/styles/colors'

export const ItemsContainer = styled.div`
  position: absolute;
  min-width: 200px;
  display: none;
  top: 50px;
  right: 45px;
`

export const Container = styled.div`
  height: 80px;
  align-items: center;
  display: flex;

  &:hover ${ItemsContainer} {
    display: block;
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
`

export const Items = styled.div`
  background-color: #fff;
  border: solid 1px #c7c8cb;
  margin-top: 10px;
`

export const Triangle = styled.div`
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg);
  border-left: solid 1px ${Colors.grey};
  border-top: solid 1px ${Colors.grey};
  position: absolute;
  top: 5px;
  right: calc(33% - 4px);
  z-index: 3;
  background-color: #fff;
`

export const DropdownItemContainer = styled.div`
  position: relative;
  display: block;
  padding: 10px;
  z-index: 3;

  &:hover {
    background-color: ${Colors.blueLight};
    color: #fff;
  }
`
