import { createContext, useContext } from 'react'
import { HTTPCollectionsGateway } from 'src/adapters/gateways/HTTPCollectionsGateway'
import { Collections } from 'src/coreLogic/useCases/collections/Collections'
import { CollectionsInterface } from 'src/coreLogic/useCases/collections/CollectionsInterface'
import * as asserts from 'src/utils/asserts'

export const collectionsFeature: CollectionsInterface = new Collections(
  new HTTPCollectionsGateway(),
)

export const CollectionsFeatureContext = createContext(collectionsFeature)

export function useCollectionsFeature() {
  const collections = useContext(CollectionsFeatureContext)

  asserts.isDefined(collections, 'feature Collections doesn\'t exist')

  return collections
}
