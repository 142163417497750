import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { LeftNav, LeftNavItem } from 'src/components/LeftNav'
import * as classes from './Book.module.css'

// Story
const Writing = React.lazy(() => import('../Writing/Writing'))
const Characters = React.lazy(() => import('../Characters'))
const InventoryItems = React.lazy(() => import('../InventoryItems'))
const Skills = React.lazy(() => import('../Skills'))
const Conditions = React.lazy(() => import('../Conditions'))
const Dashboard = React.lazy(() => import('../Dashboard'))
const Assets = React.lazy(() => import('../Assets'))
// const Images = React.lazy(() => import('../Images'))
const Variables = React.lazy(() => import('../Variables'))

// Storyboard
const Templates = React.lazy(() => import('../Templates/Templates'))
const ComponentTypes = React.lazy(() => import('../ComponentTypes'))
const UnityExports = React.lazy(() => import('../Exports/UnityExports'))
const WebExports = React.lazy(() => import('../Exports/WebExports'))

type Props = {
  match: { params: { bookId: string; }; url: string; };
}

export class Book extends React.PureComponent<Props> {
  getUrl(uri: string) {
    const { match } = this.props
    return `/books/${match.params.bookId}/${uri}`
  }

  render() {
    const { match } = this.props
    return (
      <div className={classes.container}>
        <LeftNav className={classes.nav}>
          <LeftNavItem icon="doubleArrowLeft" label="Collections" themeLight={true} to="/" />
          <LeftNavItem label="Dashboard" themeLight={true} to={this.getUrl('dashboard')} />
          <LeftNavItem label="Histoire">
            <LeftNavItem label="Ecriture" to={this.getUrl('writing')} />
            <LeftNavItem label="Personnages" to={this.getUrl('characters')} />
            <LeftNavItem label="Inventaire" to={this.getUrl('inventory')} />
            <LeftNavItem label="Compétences" to={this.getUrl('skills')} />
            <LeftNavItem label="Conditions" to={this.getUrl('conditions')} />
            <LeftNavItem label="Variables" to={this.getUrl('variables')} />
          </LeftNavItem>
          {/* <LeftNavItem label="Correction" icon={Icons.correction}>
            <LeftNavItem label="Relecture" />
            <LeftNavItem label="Versions" />
          </LeftNavItem> */}
          {/* <LeftNavItem label="Langues">
            <LeftNavItem label="Traductions" />
          </LeftNavItem> */}
          {/* <LeftNavItem label="Apparence" icon={Icons.apparence}>
            <LeftNavItem
              label="Général"
              to={this.getUrl('appearance/screens')}
            />
            <LeftNavItem label="Charte graphique" />
            <LeftNavItem label="Couverture" />
          </LeftNavItem> */}
          <LeftNavItem icon="storyboard" label="Storyboard">
            <LeftNavItem label="Component Types" to={this.getUrl('components-types')} />
            <LeftNavItem label="Gabarits" to={this.getUrl('templates')} />
            <LeftNavItem label="Assets" to={this.getUrl('assets')} />
            {/* <LeftNavItem label="Images" to={this.getUrl('images')} /> */}
            {/* <LeftNavItem label="Découpage" /> */}
          </LeftNavItem>
          {/* <LeftNavItem label="Visuels" icon={Icons.picture}>
            <LeftNavItem label="Brief Général" />
            <LeftNavItem label="Visuels" />
          </LeftNavItem>
          <LeftNavItem label="Sons" icon={Icons.music}>
            <LeftNavItem label="Brief Général" />
            <LeftNavItem label="Sons" />
          </LeftNavItem> */}
          <LeftNavItem label="Exports">
            <LeftNavItem label="Unity" to={this.getUrl('unity-exports')} />
            <LeftNavItem label="Web" to={this.getUrl('web-exports')} />
            {/* <LeftNavItem label="Images" to={this.getUrl('images')} /> */}
            {/* <LeftNavItem label="Découpage" /> */}
          </LeftNavItem>
        </LeftNav>
        <div className={classes.rightContent}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route component={Dashboard} path={`${match.url}/dashboard`} />
              <Route component={Writing} path={`${match.url}/writing/:nodeId?`} />
              <Route component={Characters} path={`${match.url}/characters`} />
              <Route component={InventoryItems} path={`${match.url}/inventory`} />
              <Route component={Skills} path={`${match.url}/skills`} />
              <Route component={Conditions} path={`${match.url}/conditions`} />
              <Route component={Variables} path={`${match.url}/variables`} />
              <Route component={Templates} path={`${match.url}/templates/:templateId?`} />
              <Route component={Assets} path={`${match.url}/assets`} />
              {/* <Route component={Images} path={`${match.url}/images`} /> */}
              <Route component={ComponentTypes} path={`${match.url}/components-types`} />
              <Route component={UnityExports} path={`${match.url}/unity-exports`} />
              <Route component={WebExports} path={`${match.url}/web-exports`} />
              <Redirect to={`${match.url}/writing`} />
            </Switch>
          </React.Suspense>
        </div>
      </div>
    )
  }
}
