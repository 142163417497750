import React, { createContext, useReducer, useContext, useRef } from 'react'
import uniqid from 'uniqid'

function addRequest(key: string) {
  return { type: 'ADD', key }
}
function removeRequest(key: string) {
  return { type: 'REMOVE', key }
}

interface State {
  [actionKey: string]: boolean;
}

interface Action {
  key: string;
  type: string;
}

function reducer(state: State = {}, action: Action): State {
  switch (action.type) {
    case 'ADD':
      return {
        ...state,
        [action.key]: true,
      }

    case 'REMOVE':
      return {
        ...state,
        [action.key]: false,
      }

    default:
      return state
  }
}

interface RequestIndicatorContextValue {
  dispatch: (action: Action) => void;
  state: State;
}

export const RequestIndicatorContext = createContext<RequestIndicatorContextValue>({} as RequestIndicatorContextValue)

export function RequestIndicatorProvider(props: { children: any; }) {
  const [state, dispatch] = useReducer(reducer, {})

  return (
    <RequestIndicatorContext.Provider value={{ state, dispatch }}>
      {props.children}
    </RequestIndicatorContext.Provider>
  )
}

export const useRequestIndicator = () => {
  const { dispatch } = useContext(RequestIndicatorContext)
  const keyRef = useRef(uniqid())

  return [
    () => dispatch(addRequest(keyRef.current)),
    () => dispatch(removeRequest(keyRef.current)),
  ]
}
