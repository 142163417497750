import cx from 'classnames'
import React from 'react'
import { Field } from 'react-form'
import validator from 'validator'
import { Message } from '../Message'
import { Field as FieldForm } from 'src/components/Form/Fields/Field'
import { Input as InputField } from 'src/components/Form/Fields/Input'
import { Colors } from 'src/styles/colors'
import * as classes from './Input.module.css'

const validatorsLabel = {
  isEmail: (value: any) => {
    return !validator.isEmail(value) && 'Email invalide'
  },
  isRequired: (value: any) => {
    return !value && 'Champs requis'
  },
}

type ValidatorAsFunction = (data: string) => string | boolean
type Validators = {
  [validatorName: string]: boolean | string | ValidatorAsFunction;
}
type Validate = (
  validators: Validators,
) => (value: string) => null | string | boolean | { error: any; }

const validate: Validate = (validators) => (value = '') => {
  if (!validators) return null
  if (!value && !validators.isRequired) return null

  // eslint-disable-next-line
  for (let [validatorKey, validatorValue] of Object.entries(validators)) {
    if (validatorValue === true || typeof validatorValue === 'string') {
      // @ts-expect-error
      if (!validatorsLabel[validatorKey]) {
        throw new Error(
          `Validator error: validator '${validatorKey}' is not valid`,
        )
      }

      // @ts-expect-error
      const defaultError = validatorsLabel[validatorKey](value)
      if (defaultError) {
        return {
          error:
            typeof validatorValue === 'string' ? validatorValue : defaultError,
        }
      }
    } else if (typeof validatorValue === 'function') {
      return validatorValue(value)
    }
  }
  return null
}

type Props = {
  className?: string;
  field: string;

  fieldClassName?: string;
  fieldStyle?: object;
  label?: string;
  onBlur?: Function;
  onChange?: Function;
  placeholder?: string;
  type?: string;
  validators?: Validators;
  // native props
  value?: string;
}

export class Input extends React.Component<Props> {
  static defaultProps = {
    type: 'text',
  }

  validate = () => {
    // @ts-expect-error
    return validate(this.props.validators)
  }

  render() {
    const {
      value: defaultValue,
      onBlur,
      onChange,
      label,
      validators,
      className,
      field,
      fieldStyle,
      fieldClassName,
      ...props
    } = this.props
    return (
      <Field
        field={field}
        validate={this.validate()}
      // preValidate={false}
      >
        {/* @ts-expect-error */}
        {(fieldApi) => {
          const {
            value,
            error,
            warning,
            success,
            setValue,
            setTouched,
          } = fieldApi
          return (
            <FieldForm
              className={fieldClassName}
              label={label}
              style={{ paddingBottom: error && 0, ...fieldStyle }}
            >
              <div className={classes.container}>
                <InputField
                  {...props}
                  className={cx(error && classes.error, className)}
                  onBlur={(e) => {
                    setTouched()
                    if (onBlur) {
                      onBlur(e)
                    }
                  }}
                  onChange={(e) => {
                    setValue(e.target.value)
                    if (onChange) {
                      onChange(e.target.value, e)
                    }
                  }}
                  value={value === undefined || value === null ? '' : value}
                />
                {error ? <Message color={Colors.red} message={error} /> : null}
                {!error && warning ? (
                  <Message color="orange" message={warning} />
                ) : null}
                {!error && !warning && success ? (
                  <Message color="green" message={success} />
                ) : null}
              </div>
            </FieldForm>
          )
        }}
      </Field>
    )
  }
}
