import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authUserActions } from 'src/coreLogic/useCases/authUser'

export function Logout() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(authUserActions.logout())
  }, [dispatch])

  return (
    <div>Logout...</div>
  )
}
