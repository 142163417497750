import React from 'react'
import * as ReactDOM from 'react-dom'
import { hijackEffects } from 'stop-runaway-react-effects'
import 'rxjs'
import './styles'
import { Error } from './components/Error'
import { Main } from './main/Main'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV !== 'production') {
  hijackEffects()
}

// @ts-ignore
window.WB_PROCESS = Object.freeze(process.env)

// @ts-expect-error
window.WB_VERSION = {
  CI_COMMIT_SHA: process.env.REACT_APP_CI_COMMIT_SHA,
  CI_COMMIT_TAG: process.env.REACT_APP_CI_COMMIT_TAG,
  CI_COMMIT_REF_NAME: process.env.REACT_APP_CI_COMMIT_REF_NAME,
  CI_COMMIT_REF_SLUG: process.env.REACT_APP_CI_COMMIT_REF_SLUG,
}

const App = () => (
  <Error>
    <Main />
  </Error>
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
