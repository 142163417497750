type SettingsType = {
  authTokenKey: string;
  env: {
    apiGraphQLURL: string;
    apiStaticURL: string;
    apiURL: string;
  };
  permissionBookHeaderKey: string;
}

const settings: SettingsType = {
  // @ts-ignore
  env: Object.freeze(window.WB_ENV), // avoid someone to override the settings in the browser console
  permissionBookHeaderKey: 'X-WB-PERMISSION-BOOK',
  authTokenKey: 'wbm_auth',
}

if (process.env.NODE_ENV === 'test') {
  // @ts-ignore
  settings.env = {}
}

export default settings
