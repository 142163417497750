import {
  createStore,
  combineReducers,
  compose,
  StateFromReducersMapObject,
  PreloadedState,
  ReducersMapObject,
  applyMiddleware,
} from 'redux'
import { Epic, createEpicMiddleware } from 'redux-observable'

interface CreateReduxStoreParams<Reducers> {
  initialState?: PreloadedState<StateFromReducersMapObject<Reducers>>;
  reducers: Reducers;
  rootEpic: Epic;
  dependencies: any;
}

export function configureStore<
  A extends never,
  S extends any,
  Reducers extends ReducersMapObject<S, A>,
>(params: CreateReduxStoreParams<Reducers>) {
  const { reducers, rootEpic, dependencies, initialState } = params

  // @ts-ignore
  // eslint-disable-next-line
  const reduxDevToolsCompose = process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

  const epicMiddleware = createEpicMiddleware({
    dependencies,
  })

  const composeEnhancers = reduxDevToolsCompose || compose
  const enhancer = composeEnhancers(applyMiddleware(epicMiddleware))

  const store = createStore(
    combineReducers<Reducers>(reducers),
    initialState,
    enhancer,
  )

  epicMiddleware.run(rootEpic)

  return store
}
