import cookies from 'js-cookie'
import { ITokenStorage } from 'src/coreLogic/dependencies/ITokenStorage'
import settings from 'src/settings'

export class CookiesTokenStorage implements ITokenStorage {
  tokenKey = settings.authTokenKey

  getToken() {
    return cookies.get(this.tokenKey) || null
  }

  setToken(token: string) {
    cookies.set(this.tokenKey, token)
  }

  removeToken() {
    cookies.remove(this.tokenKey)
  }
}
