import cx from 'classnames'
import { css } from 'emotion'
import React from 'react'
import ReactDOM from 'react-dom'
import Draggable from 'react-draggable'
import uniqid from 'uniqid'
import { Icon } from 'src/components/Icon'
import { Colors } from 'src/styles/colors'
import classes from './Modal.module.css'

const contentId = uniqid()
const modalRoot = document.getElementById('root-modal')

export type Props = {
  children: React.ReactNode | string;
  draggable?: boolean;
  minWidth?: number;
  // shouldCloseOnOverlayClick: boolean,
  onKeyUpEnter?: Function;
  // onAfterOpen: Function,
  onRequestClose?: Function;
  onRequestCloseRef?: Function;
  overflow?: string;
  showCloseBtn?: boolean;
  title: string;
  titleColor?: Colors;
}

type State = {
  left: number;
  top: number;
}

export const defaultProps = {
  // shouldCloseOnOverlayClick: false,
  overflow: 'auto',
  minWidth: 400,
  showCloseBtn: true,
  titleColor: Colors.blackNew,
  draggable: false,
}

export class Modal extends React.PureComponent<Props, State> {
  static defaultProps = defaultProps

  // eslint-disable-next-line
  static currentModalId = []

  id: string = uniqid()

  containerRef?: HTMLElement

  el: HTMLElement

  constructor(props: Props) {
    super(props)

    this.state = {
      top: 0,
      left: 0,
    }

    // @ts-expect-error
    Modal.currentModalId.push(this.id)
    this.el = document.createElement('div')
    if (props.onRequestCloseRef) {
      props.onRequestCloseRef(this.onRequestClose)
    }
  }

  componentDidMount() {
    const { draggable } = this.props
    window.addEventListener('keyup', this.onKeyUp)

    if (modalRoot) {
      modalRoot.appendChild(this.el)
    }

    if (draggable && this.containerRef && document.body) {
      const { offsetHeight, offsetWidth } = document.body
      this.setState({
        top: (offsetHeight - this.containerRef.offsetHeight) / 2,
        left: (offsetWidth - this.containerRef.offsetWidth) / 2,
      })
    }
  }

  componentWillUnmount() {
    const index = Modal.currentModalId.findIndex((id) => id === this.id)

    Modal.currentModalId.splice(index, 1)
    window.removeEventListener('keyup', this.onKeyUp)
    if (modalRoot) {
      modalRoot.removeChild(this.el)
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    if (this.isLastModalOpened()) {
      if (event.keyCode === 13) {
        const { onKeyUpEnter } = this.props
        if (onKeyUpEnter) onKeyUpEnter()
      }
    }
  }

  onRequestClose = () => {
    // console.log('Modal onRequestClose')
    const { onRequestClose } = this.props
    if (onRequestClose) onRequestClose()
    // if (modalRoot) {
    //   modalRoot.removeChild(this.el)
    // }
  }

  isLastModalOpened() {
    const { length } = Modal.currentModalId
    return length && Modal.currentModalId[length - 1] === this.id
  }

  renderTitle() {
    const { title, titleColor, draggable } = this.props

    if (!title) return null

    const titleStyle = css`
      background-color: ${titleColor};
      cursor: ${draggable ? 'move' : 'normal'};
    `

    return <div className={cx([classes.title, titleStyle])}>{title}</div>
  }

  renderCloseBtn() {
    const { showCloseBtn, title } = this.props
    if (!showCloseBtn) return null

    return (
      <div className={classes.close} onClick={this.onRequestClose}>
        <Icon icon="close" style={{ color: title ? '#fff' : '#000' }} />
      </div>
    )
  }

  render() {
    const {
      // onAfterOpen,
      // shouldCloseOnOverlayClick,
      overflow,
      children,
      title,
      draggable,
    } = this.props

    const contentClassName = cx(classes.content, {
      hasTitle: !!title,
    })

    const content = (
      <div className={classes.innerModal}>
        {this.renderTitle()}
        {this.renderCloseBtn()}
        <div
          id="contentId"
          onMouseDown={(event) => {
            event.stopPropagation()
          }}
          style={{ overflow }}
        >
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
    )

    const commonProps = {
      // @ts-expect-error
      onClick: (event) => {
        // prevent bubble event because modal is insert inside other component with React.Portal
        event.stopPropagation()
      },
    }

    const { top, left } = this.state
    const output = draggable
      ? (
        <Draggable cancel={`#${contentId}`}>
          <div
            ref={(c) => {
              // @ts-expect-error
              this.containerRef = c
            }}
            {...commonProps}
            style={{
              backgroundColor: '#fff',
              position: 'fixed',
              top,
              left,
              bottom: 'auto',
              right: 'auto',
              padding: '0px',
              maxHeight: 'calc(100% - 50px)',
              display: 'flex',
              flexDirection: 'column',
              border: 'none',
              overflow,
              minWidth: this.props.minWidth,
              borderRadius: 4,
              boxShadow: '4px 4px 15px 4px #888',
            }}
          >
            {content}
          </div>
        </Draggable>
      ) : (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            boxSizing: 'border-box',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              position: 'relative',
              top: 'auto',
              left: 'auto',
              bottom: 'auto',
              right: 'auto',
              padding: '0px',
              maxHeight: 'calc(100% - 50px)',
              display: 'flex',
              flexDirection: 'column',
              border: 'none',
              overflow,
              minWidth: this.props.minWidth,
              borderRadius: 4,
            }}
            {...commonProps}
          >
            {content}
          </div>
        </div>
      )

    return ReactDOM.createPortal(output, this.el)
  }
}
