/* eslint-disable max-classes-per-file */
/* eslint-disable react-hooks/rules-of-hooks */

import { AxiosResponse } from 'axios'
import { useRequest, useLazyRequest } from 'react-resources-store'
import { api } from 'src/adapters/gateways/API'
import { ModelCreate, ModelUpdate } from 'src/utils/TypeScriptUtils'

interface BaseModel {
  id: string;
}

interface Include<Model> {
  relation: keyof Model;
  scope?: {
    include: Include<any> | Include<any>[];
  };
}

interface Params<Model> {
  filter?: {
    include?: Include<Model>[];
    order?: string;
    where?: {
      [key in keyof Model]?: any
    };
  };
}

export class APIModel<Model extends BaseModel> {
  resourceType: string;

  constructor(url: string) {
    this.resourceType = url
  }

  get url() {
    return this.resourceType
  }

  useGetRequest<Id, Output = Id extends string ? Model : Model[]>(
    requestParams: { id?: Id; params?: Params<Model>; } = {},
    options?: Parameters<typeof useRequest>[1],
  ) {
    const { id, params } = requestParams
    return useRequest<Output>({
      resourceId: id,
      resourceType: this.resourceType,
      url: id ? `${this.url}/${id}` : this.url,
      method: 'GET',
      params,
    }, {
      fetchPolicy: 'cache-and-network',
      ...options,
    })
  }

  useCreateRequest(options?: Parameters<typeof useLazyRequest>[1]) {
    return useLazyRequest<ModelCreate<Model>, AxiosResponse<Model>>((data) => {
      return {
        resourceType: this.resourceType,
        url: this.resourceType,
        method: 'POST',
        data,
      }
    }, options)
  }

  useUpdateRequest() {
    return useLazyRequest<ModelUpdate<Model>, AxiosResponse<Model>>(({ id, ...data }) => {
      return {
        resourceId: id,
        resourceType: this.resourceType,
        url: `${this.resourceType}/${id}`,
        method: 'PATCH',
        data,
      }
    })
  }

  useDeleteRequest() {
    return useLazyRequest<string, AxiosResponse<void>>((id) => {
      return {
        resourceId: id,
        resourceType: this.resourceType,
        url: `${this.resourceType}/${id}`,
        method: 'DELETE',
      }
    })
  }

  fetch<Id, Output = Id extends string ? Model : Model[]>(
    requestParams: { id?: Id; params?: Params<Model>; } = {},
  ) {
    const { id, params } = requestParams

    return api.request<Output>({
      url: id ? `${this.url}/${id}` : this.url,
      method: 'GET',
      params,
    })
  }
}

export class APIModelBookChild<Model extends BaseModel> extends APIModel<Model> {
  get url() {
    const splitedPath = window.location.href.split('/')
    const bookId = splitedPath[3] === 'books' && splitedPath[4]
    return `books/${bookId}/${this.resourceType}`
  }
}
