import { useState, useEffect, useMemo, useRef } from 'react'
import { Observable } from 'rxjs'
import { skip } from 'rxjs/operators'

export function useObservableData<T>(observable$: Observable<T>) {
  const skipFirstRef = useRef(false)

  const defaultState = useMemo(() => {
    let output: T
    observable$.subscribe((data) => {
      output = data
      skipFirstRef.current = true
    }).unsubscribe()

    // @ts-expect-error
    return output
  }, []) // eslint-disable-line

  const [data, setData] = useState<T>(defaultState)

  useEffect(() => {
    const subscription = observable$
      .pipe(
        skip(skipFirstRef.current ? 1 : 0),
      )
      .subscribe((data) => {
        setData(data)
      })

    return () => subscription.unsubscribe()
  }, []) // eslint-disable-line

  return data
}
