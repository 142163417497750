import { useRef } from 'react'

export const useSetTimeout = () => {
  const ref = useRef<number>()

  const clearTimer = () => clearTimeout(ref.current)

  const startTimer = (fn: Function, delay: number) => {
    clearTimer()
    ref.current = setTimeout(fn, delay)
  }

  return [startTimer, clearTimer]
}
