// DO NOT EDIT. THIS IS FILE AUTO-GENERATED

export const icons = {
  apparence: 'apparence',
  arrowDown: 'arrowDown',
  correction: 'correction',
  doubleArrowLeft: 'doubleArrowLeft',
  music: 'music',
  picture: 'picture',
  storyboard: 'storyboard',
  edit: 'edit',
  trash: 'trash',
  close: 'close',
  textAlignCenter: 'textAlignCenter',
  textAlignJustify: 'textAlignJustify',
  textAlignBottom: 'textAlignBottom',
  textAlignMiddle: 'textAlignMiddle',
  textAlignRight: 'textAlignRight',
  textAlignLeft: 'textAlignLeft',
  textAlignTop: 'textAlignTop',
  add: 'add',
  arrowsH: 'arrowsH',
  arrowsV: 'arrowsV',
  check: 'check',
  floppyO: 'floppyO',
}

export const iconsClassName = {
  apparence: 'apparence',
  arrowDown: 'arrow-down',
  correction: 'correction',
  doubleArrowLeft: 'double-arrow-left',
  music: 'music',
  picture: 'picture',
  storyboard: 'storyboard',
  edit: 'edit',
  trash: 'trash',
  close: 'close',
  textAlignCenter: 'text-align-center',
  textAlignJustify: 'text-align-justify',
  textAlignBottom: 'text-align-bottom',
  textAlignMiddle: 'text-align-middle',
  textAlignRight: 'text-align-right',
  textAlignLeft: 'text-align-left',
  textAlignTop: 'text-align-top',
  add: 'add',
  arrowsH: 'arrows-h',
  arrowsV: 'arrows-v',
  check: 'check',
  floppyO: 'floppy-o',
}
