import { ActionFromMapObject, ActionsFromActionTypes } from 'src/utils/TypeScriptUtils'
import { AuthUserErrors } from './authUser.errors'
import type { AuthUserState } from './authUser.reducer'

export const AuthUserActionType = {
  LOGIN_FROM_TOKEN: 'AUTH_USER/LOGIN_FROM_TOKEN',
  SET_USER: 'AUTH_USER/SET_USER',
  REDIRECT_TO_LOGIN: 'AUTH_USER/REDIRECT_TO_LOGIN',
  REDIRECT_TO_DASHBOARD: 'AUTH_USER/REDIRECT_TO_DASHBOARD',
  LOGIN: 'AUTH_USER/LOGIN',
  LOGOUT: 'AUTH_USER/LOGOUT',
  SET_ERRORS: 'AUTH_USER/SET_ERRORS',
} as const

// ------------------------------------

export function loginFromToken() {
  return {
    type: AuthUserActionType.LOGIN_FROM_TOKEN,
  }
}

export function login(payload: {
  email: string;
  password: string;
}) {
  return {
    type: AuthUserActionType.LOGIN,
    payload,
  }
}

export function logout() {
  return {
    type: AuthUserActionType.LOGOUT,
  }
}

export function setUser(payload: AuthUserState['user']) {
  return {
    type: AuthUserActionType.SET_USER,
    payload,
  }
}

export function setErrors(payload: AuthUserErrors) {
  return {
    type: AuthUserActionType.SET_ERRORS,
    payload,
  }
}

export function redirectToLogin() {
  return {
    type: AuthUserActionType.REDIRECT_TO_LOGIN,
  }
}

export function redirectToDashboard() {
  return {
    type: AuthUserActionType.REDIRECT_TO_DASHBOARD,
  }
}

// ------------------------------------

export const publicActions = {
  loginFromToken,
  login,
  logout,
  setUser,
  setErrors,
  redirectToDashboard,
  redirectToLogin,
}

export const actions = {
  ...publicActions,
}

export type AuthUserAction = ActionFromMapObject<typeof actions>
export type AuthUserActions = ActionsFromActionTypes<typeof AuthUserActionType, AuthUserAction>
