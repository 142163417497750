import cx from 'classnames'
import React from 'react'
import classes from './Pick.module.css'

interface Option {
  label: string;
  value: any;
}

interface Props {
  onChange: Function;
  // className?: string,
  options: Option[];
  value: any;
  // multiple: boolean,
}

export class Pick extends React.PureComponent<Props> {
  static defaultProps = {
    // multiple: false,
  }

  onClickItem({ value }: Option) {
    this.props.onChange(value)
  }

  render() {
    const { options, value } = this.props
    return (
      <div>
        {options.map((item: Option) => {
          const className = cx(classes.item, {
            [classes.selected]: value === item.value,
          })

          return (
            <div
              key={item.value}
              className={className}
              onClick={() => this.onClickItem(item)}
            >
              <div className={classes.btn}>
                <span className={classes.btnInner} />
              </div>
              <span>{item.label}</span>
            </div>
          )
        })}
      </div>
    )
  }
}
