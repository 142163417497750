import cx from 'classnames'
import React from 'react'
import * as classes from './ButtonsList.module.css'

interface ButtonsListProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Simple wrapper button which add a margin between buttons
 */
export class ButtonsList extends React.Component<ButtonsListProps> {
  static defaultProps = {}

  render() {
    const { children, className, ...props } = this.props
    return (
      <div className={cx(classes.container, className)} {...props}>
        {children}
      </div>
    )
  }
}
