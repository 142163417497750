export enum Colors {
  black = '#333333',
  blackNew = '#0C1825',
  blue = '#909ba3',
  blue1 = '#4F87AF',
  blue2 = '#BECDD7',
  blueBg = '#1e2e3b',
  blueLight = '#c0cdd6',
  green = '#3ab652',
  greenDark = '#28833a',
  grey = '#c7c8cb',
  greyDark = '#4b4b4b',
  greyLight = '#9a9a9a',
  mainColor = '#909ba3',
  orange = '#f77e1d',
  red = '#ee4d53',
  redDark = '#b6373c',
  yellow = '#ffcc2b',
  yellowBorder = '#FDCB2E'
}
