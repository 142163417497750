import cx from 'classnames'
import React from 'react'
import { MessageError } from '../../ReactForm/MessageError'
import classes from './Field.module.css'

type Props = {
  children: any;
  className?: string;
  error?: string;
  label?: string;
  style?: React.CSSProperties;
}

export class Field extends React.Component<Props> {
  render() {
    const { label, children, error, style, ...props } = this.props
    return (
      <div
        {...props}
        className={cx(classes.container, { [classes.withoutLabel]: !label })}
        style={{
          ...style,
          paddingBottom: error && 0,
        }}
      >
        {label && <div className={classes.label}>{label}</div>}
        <div className={classes.fieldValue}>{children}</div>
        {error && <MessageError message={error} />}
      </div>
    )
  }
}
