import React from 'react'
import { createReducers, Schema, Provider } from 'react-resources-store'
import { api } from 'src/adapters/gateways/API'
import { relations } from './relations'

export const reducerResources = createReducers(relations as Schema)

type Resolver = React.ComponentProps<typeof Provider>['resolver']
type Request = ReturnType<Resolver>['request']

export function createAxiosResolver(axiosInstance: any) {
  return function axiosResolver(axiosConfig: any) {
    // example with url = /articles/1
    // regex will extract 'articles' in order to use it as resourceType
    const regexURL = /(\/)?([-a-zA-Z0-9()@:%_+.~#?&=]*)(\/)?([-a-zA-Z0-9()@:%_+.~#?&=]*)([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    const match = axiosConfig.url.match(regexURL)

    if (!match) {
      throw new Error('axios URL doesn\'t match REST endpoint format')
    }

    const resourceType = axiosConfig.resourceType || axiosConfig.url.match(regexURL)[2]

    if (!resourceType) {
      throw new Error('axios URL doesn\'t match REST endpoint format. It must contain a resource type')
    }

    const resourceId = axiosConfig.resourceId || axiosConfig.url.match(regexURL)[4] || null

    const request: Request = (succeeded, failed) => {
      axiosInstance.request(axiosConfig)
        .then((response: any) => {
          succeeded({
            raw: response,
            data: response.data,
          })
        })
        .catch((response: any) => {
          failed({
            raw: response,
          })

          throw response
        })
    }

    return {
      url: axiosConfig.url,
      method: axiosConfig.method,
      resourceType,
      resourceId,
      params: axiosConfig.params,
      request,
    }
  }
}

export const axiosResolver = createAxiosResolver(api)
