import React from 'react'
import { Provider } from 'react-redux'
import { Provider as ReactResourcesHookProvider, Schema } from 'react-resources-store'
import { Router, Switch, Route } from 'react-router-dom'
import { ReactRouter } from 'src/adapters/WaysbooksReactRouter'
import MainLayout from 'src/components/Layouts/Main'
import { store } from 'src/store/bootstrapStore'
import { relations } from 'src/store/legacyResources/relations'
import { axiosResolver } from 'src/store/legacyResources/resourcesConfig'
import { AppActionsProvider } from './ActionsProviders'
import { Authenticated } from './Authenticated'
import { Authentication } from './Authentication'
import { AppSelectorsProvider } from './SelectorsProvider'

export const Main = () => (
  <Provider store={store}>
    <ReactResourcesHookProvider
      resolver={axiosResolver}
      schema={relations as Schema}
      // @ts-expect-error
      store={store}
    >
      <AppActionsProvider>
        <AppSelectorsProvider>
          <Router history={ReactRouter.history}>
            <MainLayout>
              <Switch>
                <Route component={Authentication} path="/auth" />
                <Route component={Authenticated} path="/" />
              </Switch>
            </MainLayout>
          </Router>
        </AppSelectorsProvider>
      </AppActionsProvider>
    </ReactResourcesHookProvider>
  </Provider>
)
