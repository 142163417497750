import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #f00;
  padding: 20px;
`

export const Title = styled.div`
  font-size: 20px;
`
