import { from } from 'rxjs'
import { CollectionsGatewayInterface } from 'src/coreLogic/useCases/collections/CollectionsGatewayInterface'
import { resources } from 'src/store/legacyResources'

export class HTTPCollectionsGateway implements CollectionsGatewayInterface {
  fetchCollections() {
    return from(
      resources.collections
        .fetch({
          params: {
            filter: {
              include: [
                { relation: 'books' },
              ],
            },
          },
        })
        .then((res) => res.data),
    )
  }
}
