// @ts-expect-error
import PubSub from 'pubsub-js'
import React from 'react'
import uniqid from 'uniqid'
import { openModalEventName } from './openModal'

type State = {
  modals: Array<{
    element: React.ReactNode;
    id: string;
  }>;
}

export class ModalInstances extends React.PureComponent<{}, State> {
  constructor(props: {}) {
    super(props)

    this.state = {
      modals: [],
    }
  }

  componentDidMount() {
    // @ts-expect-error
    PubSub.subscribe(openModalEventName, (msg, element) => {
      const modalId = uniqid()

      const onRequestClose = () => this.closeModal(modalId)

      const modal = {
        id: modalId,
        element: React.cloneElement(element, { key: modalId, onRequestClose }),
      }

      this.setState((state) => ({
        modals: [...state.modals, modal],
      }))
    })
  }

  closeModal(modalId: string) {
    this.setState((state) => ({
      modals: state.modals.filter(({ id }) => id !== modalId),
    }))
  }

  render() {
    const { modals } = this.state
    return (
      <>{modals.map(({ element }) => element)}</>
    )
  }
}
