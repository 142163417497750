import React from 'react'
import { ModalInstances } from 'src/components/Modal'
import * as classes from './Main.module.css'

export default class MainLayout extends React.Component {
  componentDidMount() {
    // execute code on the first load page
  }

  render() {
    return (
      <div className={classes.container}>
        <ModalInstances />
        {this.props.children}
      </div>
    )
  }
}
