import React from 'react'
import ReactDOM from 'react-dom'

interface BoundingComponentProps {
  children: any;
  margin: number;
}

interface BoundingComponentState {
  isReady: boolean;
  pos: {
    left: number;
    top: number;
  };
}

export class BoundingComponent extends React.PureComponent<BoundingComponentProps, BoundingComponentState> {
  static defaultProps = {
    margin: 20,
  };

  containerRef = React.createRef<HTMLDivElement>()

  childrenRef = React.createRef<HTMLElement>()

  el: HTMLElement;

  constructor(props: BoundingComponentProps) {
    super(props)
    this.el = document.createElement('div')

    this.state = {
      isReady: false,
      pos: {
        left: 0,
        top: 0,
      },
    }
  }

  componentDidMount() {
    if (document.body) {
      document.body.appendChild(this.el)
    }

    this.setState((state) => ({
      pos: this.containerRef.current?.getBoundingClientRect() || state.pos,
      isReady: true,
    }))
  }

  componentDidUpdate(prevProps: BoundingComponentProps, prevState: BoundingComponentState) {
    const { margin } = this.props
    if (!prevState.isReady && this.state.isReady) {
      const { offsetWidth, offsetHeight } = this.childrenRef.current || { offsetWidth: 0, offsetHeight: 0 }
      const { pos: { top, left } } = this.state
      const nextStatePos = { top, left }
      if (top + offsetHeight > window.innerHeight) {
        nextStatePos.top = window.innerHeight - offsetHeight - margin
      } else if (top < margin) {
        nextStatePos.top = margin
      }
      if (left + offsetWidth > window.innerWidth) {
        nextStatePos.left = window.innerWidth - offsetWidth - margin
      } else if (left < margin) {
        nextStatePos.left = margin
      }

      // eslint-disable-next-line
      this.setState({ pos: nextStatePos });
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeChild(this.el)
    }
  }

  render() {
    const { children } = this.props
    const { isReady, pos: { left, top } = { top: undefined, left: undefined } } = this.state

    return !isReady ? (
      <div ref={this.containerRef} />
    ) : ReactDOM.createPortal(
      <div style={{ position: 'fixed', left, top, zIndex: 3 }}>
        {React.cloneElement(children, {
          ref: this.childrenRef,
        })}
      </div>,
      this.el,
    )
  }
}
