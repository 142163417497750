import cx from 'classnames'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from 'src/components/Icon'
import { IconType } from 'src/styles/icons'
import * as classes from './LeftNavItem.module.css'

export type Props = {
  children?: React.ReactNode;
  disabled: boolean;
  icon?: IconType;
  label: string;
  themeLight?: boolean;
  to?: string;
}

type State = {
  isOpen: boolean;
}

export class LeftNavItem extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
  }

  static getDerivedStateFromProps(props: Props, prevState: State) {
    if (prevState.isOpen) return null
    if (props.children) {
      const asArray: Array<any> = Array.isArray(props.children)
        ? props.children
        : [props.children]

      if (
        asArray.find(
          (child) => child.props.to
            && window.location.pathname.startsWith(child.props.to),
        )
      ) {
        return {
          isOpen: true,
        }
      }
    }

    return null
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  onClick = () => {
    const { to } = this.props
    if (!to) {
      this.setState((prev) => ({ isOpen: !prev.isOpen }))
    }
  }

  render() {
    const { label, icon, to, children, themeLight, disabled } = this.props
    const { isOpen } = this.state
    const isParent = !!children

    const content = (
      <>
        <div
          className={cx(
            classes.labelContainer,
            themeLight && classes.themeLight,
            disabled && classes.disabled,
          )}
        >
          {isParent && (
            <Icon
              className={classes.arrow}
              icon="arrowDown"
              style={{ transform: !isOpen && 'rotateZ(-90deg)' }}
            />
          )}
          {icon && <Icon className={classes.icon} icon={icon} />}
          <div>{label}</div>
        </div>
        {isOpen && children}
      </>
    )

    const containerClassName = cx(
      classes.container,
      children ? classes.isParent : null,
    )

    return to
      ? (
        <NavLink
          activeClassName={classes.active}
          className={containerClassName}
          to={to}
        >
          {content}
        </NavLink>
      ) : (
        <div
          className={cx(containerClassName, !children && classes.disabled)}
          onClick={this.onClick}
        >
          {content}
        </div>
      )
  }
}
