import produce from 'immer'
import React, { createContext, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { authUserActions } from 'src/coreLogic/useCases/authUser'

const actions = {
  authUser: authUserActions,
}

type ActionsContextValue = typeof actions

export type PartialActionsProvidersValue = Partial<{
  [Key in keyof ActionsContextValue]: Partial<ActionsContextValue[Key]>
}>

const ActionsContext = createContext<ActionsContextValue>({} as ActionsContextValue)

export function ActionsProvider(props: { children: React.ReactNode; actions: PartialActionsProvidersValue; }) {
  const { children, actions } = props

  return (
    <ActionsContext.Provider value={actions as ActionsContextValue}>
      {children}
    </ActionsContext.Provider>
  )
}

export function useActions() {
  const context = useContext(ActionsContext)

  if (!context) {
    throw new Error('context Actions can\'t be used outside context ActionsProviders')
  }

  return context
}

export function AppActionsProvider(props: { children: React.ReactNode; }) {
  const { children } = props
  const dispatch = useDispatch()

  const bindedActions = useMemo(() => {
    return produce(actions, (draft) => {
      Object.values(draft).forEach((actionsScope) => {
        Object.entries(actionsScope).forEach(([key, action]) => {
          // @ts-expect-error
          // eslint-disable-next-line
          actionsScope[key] = (...args: any[]) => dispatch(action(...args))
        })
      })
    })
  }, [dispatch])

  return (
    <ActionsProvider actions={bindedActions}>
      {children}
    </ActionsProvider>
  )
}
