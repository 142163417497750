import { map, distinctUntilChanged } from 'rxjs/operators'
import { Store } from 'src/utils/observables/Store'
import { CollectionsGatewayInterface } from './CollectionsGatewayInterface'
import { CollectionsInterface } from './CollectionsInterface'
import { CollectionsState } from './CollectionsState'

const intialState: CollectionsState = {
  pending: false,
  collections: [],
}

export class Collections extends Store<CollectionsState> implements CollectionsInterface {
  collectionsPending$ = this.state$.pipe(
    map((state) => state.pending),
    distinctUntilChanged(),
  );

  collections$ = this.state$.pipe(map((state) => state.collections), distinctUntilChanged());

  constructor(
    private gateway: CollectionsGatewayInterface,
  ) {
    super(intialState)
  }

  retrieveCollections() {
    this.setState({ pending: true })

    this.gateway.fetchCollections()
      .subscribe(
        (collections) => {
          this.setState({
            collections,
            pending: false,
          })
        },
      )
  }
}
