export const EmailErrors = {
  REQUIRED: 'REQUIRED',
  INVALID_EMAIL: 'INVALID_EMAIL',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
} as const

type EmailErrors = keyof typeof EmailErrors

// ------------------------------------

export const PasswordErrors = {
  REQUIRED: 'REQUIRED',
} as const

type PasswordErrors = keyof typeof PasswordErrors

// ------------------------------------

export interface AuthUserErrors {
  email?: EmailErrors;
  password?: PasswordErrors;
}
