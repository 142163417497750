import styled from 'styled-components'

export const Container = styled.div`
  .field {
    display: flex;
  }

  .field > *:last-child:not(:first-child) {
    margin-left: 10px;
  }

  .subConditionsList {
    margin-top: 20px;
    padding: 20px;
    border: var(--mainBorder);
  }

  .subCondition {
    /* margin: 20px 0; */
    display: flex;
  }

  .subCondition > *:first-child {
    flex: 1;
  }

  .deleteBtn {
    margin-left: 20px;
  }
`
