export function isString(val: any, msg?: string): asserts val is string {
  if (typeof val !== 'string') {
    throw new Error(`Assertion Error: string. ${msg}`)
  }
}

export function isNumber(val: any, msg?: string): asserts val is number {
  if (typeof val !== 'number') {
    throw new Error(`Assertion Error: number. ${msg}`)
  }
}

export function isBoolean(val: any, msg?: string): asserts val is boolean {
  if (typeof val !== 'boolean') {
    throw new Error(`Assertion Error: boolean. ${msg}`)
  }
}

export function isFunction(val: any, msg?: string): asserts val is Function {
  if (typeof val !== 'function') {
    throw new Error(`Assertion Error: function. ${msg}`)
  }
}

export function isDefined<T>(val: T, msg?: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(`Assertion Error: undefined. ${msg}`)
  }
}
