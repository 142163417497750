import { router } from 'src/adapters/WaysbooksReactRouter'
import { api } from 'src/adapters/gateways/API'
import { CookiesTokenStorage } from 'src/adapters/gateways/CookiesTokenStorage'
import { HTTPAuthUserGateway } from 'src/adapters/gateways/HTTPAuthUserGateway'
import { AppDependencies } from 'src/coreLogic/dependencies'
import { configureStore } from 'src/store/configureStore'
import { reducers } from './reducers'
import { rootEpic } from './rootEpic'

function createDependencies(): AppDependencies {
  return {
    authUserGateway: new HTTPAuthUserGateway(api),
    tokenStorage: new CookiesTokenStorage(),
    router,
  }
}

function bootstrapStore() {
  return configureStore({
    reducers,
    rootEpic,
    dependencies: createDependencies(),
  })
}

export const store = bootstrapStore()
