import React from 'react'
import { Form as ReactForm } from 'react-form'
import { Button } from 'src/components/Buttons'
import { ModalFormContext } from 'src/components/Modal/ModalConfirmForm'
import { Colors } from 'src/styles/colors'
import * as classes from './Form.module.css'
import { Message } from './Message'

type Props = {
  defaultValidateButton?: boolean;
  getApi?: Function;
  // rect-form props
  onSubmit: Function;
  onSubmitFailure?: Function;

  render: Function;
  style?: Object;
  submitButtonLabel?: React.ReactNode;
  submitButtonProps?: Object;
  validateOnSubmit?: boolean;
  values?: any;
}

type State = {
  errorMessage?: string;
}

export class Form extends React.Component<Props, State> {
  static defaultProps = {
    submitButtonLabel: 'Valider',
    defaultValidateButton: true,
  }

  // @ts-expect-error
  contextValue: {
    getApi: Function;
    onSubmitFailure: Function;
    onSubmited: Function;
  }

  constructor(props: Props) {
    super(props)

    this.state = {}
  }

  onSubmit = (...args: any) => {
    const { onSubmit } = this.props

    if (!onSubmit) return null

    // this.setState({ isLoading: true });
    return (
      onSubmit(...args)
        // @ts-expect-error
        .then((res) => {
          if (this.contextValue) {
            this.contextValue.onSubmited(res)
          }
          return res
        })
        // .then(e => console.log(e))
        // @ts-expect-error
        .catch((error) => {
          // if it's a server request
          if (error && error.response && error.response.data) {
            const { data } = error.response
            if (data.error && data.error.message) {
              this.setState({ errorMessage: data.error.message })
              return null
            }
          } else {
            this.setState({ errorMessage: 'Erreur' })
          }

          throw new Error(error)
        })
    )
  }

  onSubmitFailure = (...args: Array<any>) => {
    const { onSubmitFailure } = this.props
    if (this.contextValue) {
      this.contextValue.onSubmitFailure(...args)
    }

    if (onSubmitFailure) {
      return onSubmitFailure(...args)
    }

    throw args[1]
  }

  getApi = (api: any) => {
    const { getApi } = this.props
    if (getApi) {
      getApi(api)
    }

    if (this.contextValue) {
      this.contextValue.getApi(api)
    }
  }

  render() {
    const {
      onSubmit,
      render,
      submitButtonProps,
      submitButtonLabel,
      defaultValidateButton,
      style,
      getApi,
      ...props
    } = this.props
    const { errorMessage } = this.state

    return (
      <ModalFormContext.Consumer>
        {(contextValue) => {
          if (contextValue) {
            // @ts-ignore
            this.contextValue = contextValue
          }

          return (
            <ReactForm
              {...props}
              getApi={this.getApi}
              onSubmit={this.onSubmit}
              onSubmitFailure={this.onSubmitFailure}
              // @ts-expect-error
              render={(apiForm) => {
                const { submitForm, submitting } = apiForm
                const validateButtonEl = (
                  <Button
                    className={classes.submitButton}
                    color={Colors.green}
                    isLoading={submitting}
                    type="submit"
                    {...submitButtonProps}
                    data-testid="form-submit-button"
                  >
                    {submitButtonLabel}
                  </Button>
                )

                return (
                  <form onSubmit={submitForm} style={style}>
                    {errorMessage
                      && !submitting && <Message>{errorMessage}</Message>}
                    {render(apiForm, validateButtonEl)}
                    {!contextValue && defaultValidateButton && (
                      <div className={classes.btns}>{validateButtonEl}</div>
                    )}
                  </form>
                )
              }}
              validateOnSubmit
            />
          )
        }}
      </ModalFormContext.Consumer>
    )
  }
}
