import cx from 'classnames'
import React from 'react'
import * as classes from './LeftNav.module.css'

type Props = {
  children: React.ReactNode;
  className?: string;
}

export class LeftNav extends React.Component<Props> {
  render() {
    const { children, className, ...props } = this.props
    return (
      <div className={cx(classes.container, className)} {...props}>
        {children}
      </div>
    )
  }
}
