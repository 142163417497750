import React from 'react'
import { Container, Title } from './Error.styles'

interface ErrorProps {
  message: string;
}

interface ErrorState {
  error: any;
  info: React.ErrorInfo | null;
}

export class Error extends React.PureComponent<ErrorProps, ErrorState> {
  static defaultProps = {
    message: 'Sorry, An error occur :(',
  }

  constructor(props: ErrorProps) {
    super(props)

    this.state = {
      error: null,
      info: null,
    }
  }

  componentDidCatch(error: any, info: React.ErrorInfo) {
    this.setState({ error, info })
  }

  onToggleSack = () => {
    alert(this.state.info?.componentStack) // eslint-disable-line
  }

  onReload = () => {
    window.location.reload()
  }

  render() {
    const { message, children } = this.props
    const { error } = this.state

    if (error) {
      return (
        <Container>
          <div>
            <Title>{message}</Title>
            <div>{error.toString()}</div>
            {/* <ButtonsList>
              <Button onClick={this.onToggleSack}>Show stack</Button>
              <Button onClick={this.onReload}>Reload page</Button>
            </ButtonsList> */}
          </div>
        </Container>
      )
    }

    return children
  }
}
