import React from 'react'

type PropsMessage = {
  color: React.CSSProperties['color'];
  message: string;
}

export class Message extends React.PureComponent<PropsMessage> {
  render() {
    const { message, color } = this.props
    return (
      <div style={{ color, textAlign: 'right', lineHeight: '25px' }}>
        <span>{message}</span>
      </div>
    )
  }
}
