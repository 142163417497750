import React from 'react'
import { ModalConfirm } from './ModalConfirm'
import { openModal } from './openModal'

export const confirm = (modalProps: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const { render, onCancel, onValidate, ...restModalProps } = modalProps
    const children = typeof render === 'function' ? render() : render

    openModal(
      <ModalConfirm
        onCancel={() => {
          if (onCancel) {
            return onCancel()
          }
          return reject()
        }}
        onValidate={() => {
          if (onValidate) {
            return onValidate()
          }
          resolve()
          return true
        }}
        {...restModalProps}
      >
        {children}
      </ModalConfirm>,
    )
  })
}
