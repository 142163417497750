import React, { useCallback } from 'react'
import styled from 'styled-components'
import { CookiesTokenStorage } from 'src/adapters/gateways/CookiesTokenStorage'
import { Button, ButtonsList } from 'src/components/Buttons'
import settings from 'src/settings'
import * as asserts from 'src/utils/asserts'
import { copyTextToClipboard } from 'src/utils/copyTextToClipboard'

const Container = styled.div`
  padding: 20px;
`

export function Account() {
  const copyAccessToken = useCallback(() => {
    const token = new CookiesTokenStorage().getToken()
    asserts.isDefined(token, 'Token can\t be undefined on Account page')
    copyTextToClipboard(token)
  }, [])

  const openExplorer = useCallback(() => {
    const explorerURL = `${settings.env.apiURL.slice(0, -4)}/explorer`
    window.open(explorerURL, '_blank')
  }, [])

  return (
    <Container>
      <ButtonsList>
        <Button onClick={copyAccessToken}>
          Copy Access Token
        </Button>
        <Button onClick={openExplorer}>
          Open explorer
        </Button>
      </ButtonsList>
    </Container>
  )
}
