import * as asserts from 'src/utils/asserts'

export const useBookId = () => {
  // hack because /books/:bookId/preview doesn't use redux store
  const splitedPath = window.location.href.split('/')
  return splitedPath[3] === 'books' ? splitedPath[4] : null
}

export function useNonNullableBookId(message?: string) {
  const bookId = useBookId()
  asserts.isDefined(bookId, message || 'bookId can\'t be undefined')

  return bookId
}
