import { Action } from 'redux'
import { ActionsObservable, StateObservable } from 'redux-observable'
import { EMPTY, of, concat, Observable, from } from 'rxjs'
import { map, switchMap, catchError } from 'rxjs/operators'
import { AppDependencies } from 'src/coreLogic/dependencies'
import { AppState } from 'src/store/reducers'
import { ofType } from 'src/utils/ofType'
import { AuthUserAction, AuthUserActionType, actions } from './authUser.actions'
import { EmailErrors } from './authUser.errors'
import { AuthUserErrorBadCredentials } from './authUser.exceptions'
import { validateEmail, validatePassword } from './authUser.validations'

// ------------------------------------------------------------

function switchMapForType<A extends Action, T extends A['type'], O extends Observable<any>>(
  type: T,
  map: (action: Extract<A, { type: T; }>) => O,
) {
  function isType(action: A): action is Extract<A, { type: T; }> {
    return action.type === type
  }

  return (source: Observable<A>): Observable<A extends Extract<A, { type: T; }> ? O : A> => {
    return source.pipe(
      // @ts-expect-error
      switchMap((action: A): O | A => {
        if (isType(action)) {
          return map(action)
        }

        // @ts-expect-error
        return of(action)
      }),
    )
  }
}

// ------------------------------------------------------------

export function epicAuthenticateFromToken(
  action$: ActionsObservable<AuthUserAction>,
  state$: StateObservable<AppState>,
  dependencies: AppDependencies,
) {
  return action$.pipe(
    ofType(AuthUserActionType.LOGIN_FROM_TOKEN),
    switchMap(() => {
      const token = dependencies.tokenStorage.getToken()
      if (token) {
        return from(dependencies.authUserGateway.authenticateUserFromToken(token)).pipe(
          map((userOrNull) => {
            return !userOrNull
              ? actions.redirectToLogin()
              : actions.setUser(userOrNull)
          }),
        )
      }

      return of(actions.redirectToLogin())
    }),
  )
}

export function epicRedirectToLogin(
  action$: ActionsObservable<AuthUserAction>,
  state$: StateObservable<AppState>,
  dependencies: AppDependencies,
) {
  return action$.pipe(
    ofType(AuthUserActionType.REDIRECT_TO_LOGIN),
    switchMap(() => {
      dependencies.router.redirectToLogin()

      return EMPTY
    }),
  )
}

export function epicRedirectToDashboard(
  action$: ActionsObservable<AuthUserAction>,
  state$: StateObservable<AppState>,
  dependencies: AppDependencies,
) {
  return action$.pipe(
    ofType(AuthUserActionType.REDIRECT_TO_DASHBOARD),
    switchMap(() => {
      dependencies.router.redirectToDashboard()

      return EMPTY
    }),
  )
}

export function epicLogin(
  action$: ActionsObservable<AuthUserAction>,
  state$: StateObservable<AppState>,
  dependencies: AppDependencies,
) {
  return action$.pipe(
    ofType(AuthUserActionType.LOGIN),
    switchMap((action) => {
      const { email, password } = action.payload

      const errors = {
        email: validateEmail(email),
        password: validatePassword(password),
      }

      const hasError = Object.values(errors).some(Boolean)
      if (hasError) {
        return of(actions.setErrors(errors))
      }

      return concat(
        of(actions.setErrors({})),
        of(action),
      )
    }),
    switchMapForType(AuthUserActionType.LOGIN, (action) => {
      const { email, password } = action.payload

      return from(dependencies.authUserGateway.loginUser({ email, password })).pipe(
        map((tokenOrNull) => {
          if (tokenOrNull) {
            dependencies.tokenStorage.setToken(tokenOrNull)
            return actions.redirectToDashboard()
          }

          return actions.redirectToLogin()
        }),
        catchError((error) => {
          if (error instanceof AuthUserErrorBadCredentials) {
            return of(actions.setErrors({
              email: EmailErrors.BAD_CREDENTIALS,
            }))
          }

          return EMPTY
        }),
      )
    }),
  )
}

export function epicLogout(
  action$: ActionsObservable<AuthUserAction>,
  state$: StateObservable<AppState>,
  dependencies: AppDependencies,
) {
  return action$.pipe(
    ofType(AuthUserActionType.LOGOUT),
    switchMap(() => {
      return from(dependencies.authUserGateway.logoutUser()).pipe(
        map(() => {
          dependencies.tokenStorage.removeToken()

          return actions.redirectToLogin()
        }),
      )
    }),
  )
}

export const authUserEpics = [
  epicAuthenticateFromToken,
  epicRedirectToLogin,
  epicRedirectToDashboard,
  epicLogin,
  epicLogout,
]
