import React from 'react'
import * as S from './Select.styles'

type Option = {
  children?: Array<Option>;
  disabled?: boolean;
  group?: string;
  label: string;
  selected?: boolean;
  value?: any;
}

interface SelectProps {
  className?: string;
  disabled?: boolean;
  innerRef?: any;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<Option>;
  style?: React.CSSProperties;
  value?: string;
}

export class Select extends React.PureComponent<SelectProps> {
  static defaultProps = {}

  render() {
    const {
      value,
      options,
      innerRef,
      ...props
    } = this.props

    const valueNotNull = value === null ? '' : value

    return (
      <S.Container>
        <S.Select
          ref={innerRef}
          value={valueNotNull}
          {...props}
        >
          {options.map(({ label, children, ...rest }) => {
            if (children) {
              return (
                <optgroup key={label} label={label}>
                  {children.map(({ label, ...rest }) => (
                    <option key={rest.value} {...rest}>
                      {label}
                    </option>
                  ))}
                </optgroup>
              )
            }

            return (
              <option key={rest.value} {...rest}>
                {label}
              </option>
            )
          })}
        </S.Select>
      </S.Container>
    )
  }
}
