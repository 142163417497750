import React, { useContext } from 'react'
import { Loader } from 'src/components/Loader/Loader'
import { RequestIndicatorContext } from 'src/hooks'
import * as classes from './Authenticated.module.css'

export function SavingState() {
  const { state } = useContext(RequestIndicatorContext)

  if (Object.values(state).filter((value) => value).length === 0) return null

  return (
    <div className={classes.saving}>
      <Loader size="small" />
      <div className={classes.savingContent}>Enregistrement en cours...</div>
    </div>
  )
}
