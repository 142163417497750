import React from 'react'
import { SketchPicker, ColorResult } from 'react-color'
import styled from 'styled-components'
import { BoundingComponent } from 'src/components/BoundingComponent'

type ColorPickerProps = {
  defaultValue: string;
  onChange: Function;
}

type ColorPickerState = {
  color: string;
  displayColorPicker: boolean;
}

const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
`
const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`
const Popover = styled.div`
  position: absolute;
  z-index: 2;
`
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`

export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  static getDerivedStateFromProps(props: ColorPickerProps) {
    return {
      color: props.defaultValue,
    }
  }

  constructor(props: ColorPickerProps) {
    super(props)

    this.state = {
      displayColorPicker: false,
      color: props.defaultValue,
    }
  }

  onChange = (color: ColorResult) => {
    const { onChange } = this.props
    this.setState({ color: color.hex })
    onChange(color)
  }

  handleClick = () => {
    this.setState((state) => ({ displayColorPicker: !state.displayColorPicker }))
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  render() {
    const { color } = this.state
    return (
      <div>
        <Swatch onClick={this.handleClick}>
          <Color style={{ backgroundColor: color }} />
        </Swatch>
        {this.state.displayColorPicker ? (
          <BoundingComponent>
            <Popover>
              <Cover onClick={this.handleClose} />
              <SketchPicker color={color} onChange={this.onChange} />
            </Popover>
          </BoundingComponent>
        ) : null}
      </div>
    )
  }
}
