import React from 'react'
import { Colors } from 'src/styles/colors'
import { Message } from './Message'

interface Props {
  field?: {
    type: string;
  };
  message?: string;
}

export function MessageError(props: Props) {
  if (props.message) {
    return <Message color={Colors.red} message={props.message} />
  }

  if (props.field) {
    return <Message color={Colors.red} message={props.field.type} />
  }

  return null
}
