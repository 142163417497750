import React from 'react'
import * as classes from './Style.module.css'

export class Layout extends React.Component {
  render() {
    return (
      <div className={classes.layout}>
        <div className={classes.layoutContainer}>
          <div className={classes.logoContainer}>
            <div className={classes.logo} />
            <div className={classes.maker}>Maker</div>
          </div>
          <div className={classes.formContainer}>{this.props.children}</div>
        </div>
      </div>
    )
  }
}
