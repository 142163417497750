import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { combineLatest, Observable } from 'rxjs'
import { throttleTime, distinctUntilChanged } from 'rxjs/operators'
import { collectionsFeature } from 'src/containers/CollectionsFeature/CollectionsFeature'
import bookImg from 'src/img/book-cover.svg'
import { useObservableData } from 'src/utils/observables/useObservableData'
import {
  Container,
  CollectionsList,
  CollectionContainer,
  Header,
  HeaderLeft,
  CollectionName,
  Books,
  Book,
  Picture,
  BookName,
} from './Collections.styles'

function throttleTimePending(delay: number = 300) {
  return function operator<T>(source: Observable<T>) {
    return source.pipe(
      throttleTime(delay, undefined, { leading: true, trailing: true }),
      distinctUntilChanged(),
    )
  }
}

export function Collections() {
  const [loading, collections] = useObservableData(
    combineLatest([
      collectionsFeature.collectionsPending$,
      collectionsFeature.collections$,
    ]).pipe(throttleTimePending(100)),
  )

  useEffect(() => {
    collectionsFeature.retrieveCollections()
  }, [])

  return loading
    ? (
      <div>Loading...</div>
    ) : (
      <Container>
        <CollectionsList>
          {collections.map((collection) => (
            <CollectionContainer key={collection.id}>
              <Header>
                <HeaderLeft>
                  <CollectionName>
                    {collection.name}
                  </CollectionName>
                </HeaderLeft>
              </Header>
              <Books>
                {collection.books?.map((book) => (
                  <Book key={book.id}>
                    <Link to={`/books/${book.id}`}>
                      <Picture style={{ backgroundImage: `url(${bookImg})` }} />
                      <BookName>
                        {book.name}
                      </BookName>
                    </Link>
                  </Book>
                ))}
              </Books>
            </CollectionContainer>
          ))}
        </CollectionsList>
      </Container>
    )
}
