import cx from 'classnames'
import React from 'react'
import * as classes from './Loader.module.css'

type Props = {
  overlay: boolean;
  size: 'small' | 'normal' | 'large';
  style?: React.CSSProperties;
}

export class Loader extends React.PureComponent<Props> {
  static defaultProps = {
    size: 'normal',
    overlay: false,
  }

  static scale = {
    small: 1,
    normal: 2,
    large: 4,
  }

  render() {
    const { size, style, overlay, ...props } = this.props

    return overlay
      ? (
        <div className={classes.overlay}>
          {/* @ts-expect-error */}
          <div {...props} className={cx(classes.loader, classes[size])} />
        </div>
      ) : (
        // @ts-expect-error
        <div {...props} className={cx(classes.loader, classes[size])} />
      )
  }
}
