import { APIModel, APIModelBookChild } from './APIModel'
import * as Models from './types'

export const resources = {
  assetImages: new APIModelBookChild<Models.AssetImage>('assetImages'),
  books: new APIModel<Models.Book>('books'),
  collections: new APIModel<Models.Collection>('collections'),
  imagesNodes: new APIModelBookChild<Models.ImageNode>('imagesNodes'),
  nodeLinks: new APIModel<Models.NodeLink>('nodeLinks'),
  nodeTexts: new APIModel<Models.NodeText>('nodeTexts'),
  screenComponents: new APIModel<Models.ScreenComponent>('screenComponents'),
  screens: new APIModel<Models.Screen>('screens'),
  tagsImages: new APIModelBookChild<Models.TagImage>('tagsImages'),
  templateComponents: new APIModel<Models.TemplateComponent>('templateComponents'),
  componentTypeProperties: new APIModel<Models.ComponentTypeProperty>('componentTypeProperties'),

  // book relations
  assets: new APIModelBookChild<Models.Asset>('assets'),
  characters: new APIModelBookChild<Models.Character>('characters'),
  componentTypes: new APIModelBookChild<Models.ComponentType>('componentTypes'),
  conditions: new APIModelBookChild<Models.Condition>('conditions'),
  images: new APIModelBookChild<Models.Image>('images'),
  inventoryItems: new APIModelBookChild<Models.InventoryItem>('inventoryItems'),
  nodes: new APIModel<Models.Node>('nodes'),
  skills: new APIModelBookChild<Models.Skill>('skills'),
  tags: new APIModelBookChild<Models.Tag>('tags'),
  templates: new APIModelBookChild<Models.Template>('templates'),
  variables: new APIModelBookChild<Models.Variable>('variables'),
}
