import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ReactRouter } from 'src/adapters/WaysbooksReactRouter'
import { CookiesTokenStorage } from 'src/adapters/gateways/CookiesTokenStorage'
import { Notification } from 'src/adapters/gateways/Notification'
import { getBookId } from 'src/adapters/getBookId'
import { INotification } from 'src/coreLogic/dependencies/INotification'
import { IRouter } from 'src/coreLogic/dependencies/IRouter'
import { ITokenStorage } from 'src/coreLogic/dependencies/ITokenStorage'
import settings from 'src/settings'

export class API {
  private axiosInst: AxiosInstance

  constructor(
    private notificaton: INotification,
    private tokenStorage: ITokenStorage,
    private router: IRouter,
    private getBookId: () => string | null,
    private config: {
      url: string;
      permissionBookHeaderKey: string;
    },
  ) {
    this.axiosInst = axios.create({
      baseURL: this.config.url,
      timeout: 20000,
    })

    this.axiosInst.interceptors.request.use(
      (config) => {
        const token = this.tokenStorage.getToken()
        if (token) {
          // eslint-disable-next-line
          config.headers = {
            ...config.headers,
            ...this.getAuthHeaders(),
            ...this.getAuthHeadersBook(),
          }
        }
        return config
      },
      (err) => {
        return Promise.reject(err)
      },
    )

    this.axiosInst.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401 && !this.router.isLoginPage()) {
          this.router.redirectToLogin()
        } else {
          throw error
        }
      },
    )
  }

  request<Output>(params: AxiosRequestConfig) {
    return this.axiosInst.request<Output>(params)
  }

  getAuthHeaders = () => {
    const token = this.tokenStorage.getToken()
    if (!token) return null
    return {
      Authorization: token,
    }
  }

  getAuthHeadersBook = () => {
    const bookId = this.getBookId()
    if (!bookId) return null
    return {
      [this.config.permissionBookHeaderKey]: bookId,
    }
  }
}

export const api = new API(
  new Notification(),
  new CookiesTokenStorage(),
  new ReactRouter(),
  getBookId,
  {
    url: settings.env.apiURL,
    permissionBookHeaderKey: settings.permissionBookHeaderKey,
  },
)
