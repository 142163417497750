import React from 'react'
import { Container, Label, Triangle, ItemsContainer, Items } from './Dropdown.styles'

interface DropdownProps {
  children: React.ReactNode;
  label: string;
}

export class Dropdown extends React.PureComponent<DropdownProps> {
  render() {
    return (
      <Container>
        <Label>
          {this.props.label}
        </Label>
        <ItemsContainer>
          <Triangle />
          <Items>{this.props.children}</Items>
        </ItemsContainer>
      </Container>
    )
  }
}
