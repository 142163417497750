import styled from 'styled-components'
import { Colors } from 'src/styles/colors'

export const Container = styled.div`
  padding: 40px;
`

export const CollectionsList = styled.ul`
  margin: 10px 0 20px 0px;
`

export const CollectionContainer = styled.li`
  border: solid 1px ${Colors.blueLight};
  padding: 25px;
  margin-bottom: 40px;
`

export const Header = styled.div`
  padding-bottom: 15px;
  border-bottom: solid 1px ${Colors.blueLight};
  display: flex;
  align-items: flex-start;
`

export const HeaderLeft = styled.div`
  flex-grow: 1;
`

export const HeaderRight = styled.div`
  flex-grow: 0;
  display: flex;
  padding-left: 50px;
`

export const CollectionName = styled.div`
  font-family: 'ralewaybold';
  font-size: 22px;
  color: var(--color-colorBlue);
  text-transform: uppercase;
  padding-bottom: 5px;
`

export const Books = styled.ul`
  display: flex;
  flex-wrap: wrap;
`

export const Book = styled.li`
  margin-top: 25px;
  border: solid 1px ${Colors.blueLight};
  width: 210px;
  position: relative;
  display: flex;

  a {
    display: flex;
    flex-direction: column;
  }

  &[data-roles="none"] {
    opacity: 0.4;

    a {
      cursor: not-allowed;
    }
  }

  &:not(:last-child) {
    margin-right: 30px;
  }
`

export const Picture = styled.div`
  flex-grow: 0;
  position: relative;
  width: 210px;
  height: 160px;
  background-size: cover;
  background-position: center;
  background-color: ${Colors.blueLight};
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;

  .add-new {
    flex-grow: 0;
  }
`

export const BookName = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'ralewaybold';
  line-height: 25px;

  .index {
    text-transform: uppercase;
  }
`
