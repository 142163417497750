import { BehaviorSubject } from 'rxjs'

export class Store<State> {
  private store = new BehaviorSubject<State>(this.state);

  state$ = this.store.asObservable();

  constructor(private state: State) {
    // console.log('this.constructor.name', this.constructor.name)
  }

  protected setState(nextPartialState: Partial<State>) {
    const nextState = { ...this.state, ...nextPartialState }
    // this.logStateChange(this.state, nextState)
    this.store.next(nextState)
  }

  getState() {
    return this.state
  }

  // private logStateChange(prevState: State, nextState: State) {
  //   // eslint-disable-next-line
  //   // console.log(`${this.constructor.name}`, { prevState, nextState })
  // }
}
